/**
 * @generated SignedSource<<8710a1b32eeae901b94ec04d7245f7b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSharedUrgencySignals_item$data = ReadonlyArray<{
  readonly isOnHold: boolean | null;
  readonly isSold: boolean | null;
  readonly isUnavailable: boolean | null;
  readonly itemType: string | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "useSharedUrgencySignals_item";
}>;
export type useSharedUrgencySignals_item$key = ReadonlyArray<{
  readonly " $data"?: useSharedUrgencySignals_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSharedUrgencySignals_item">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useSharedUrgencySignals_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOnHold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUnavailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemType",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "14fc23698bb37fde0cdeabf2f6f707bc";

export default node;
